
import EquipmentCard from '../components/Equipment/Card.vue';
import EquipmentFilters from '../components/Equipment/Filters.vue';
import { getCategory, useCategory } from '../store/categories';
import {
  addFacet,
  toggleFacet,
  searchEquipment,
  filterEquipment,
  resetSearch,
  numberOfPages,
  useFacetFilters,
  useNumericFilters,
  usePage,
  useQuery,
  isFacetActive,
  useAlgoliaResponse,
} from '../store/search';
import { defineComponent } from 'vue';
import SearchPagination from '../components/SearchPagination.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'CategoryView',
  components: {
    EquipmentCard,
    SearchPagination,
    EquipmentFilters,
  },
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  setup() {
    const current = useCategory();
    const algoliaResponse = useAlgoliaResponse();
    const facetFilters = useFacetFilters();
    const numericFilters = useNumericFilters();
    const page = usePage();
    const query = useQuery();
    const route = useRoute();

    getCategory(route.params.category as string);
    resetSearch();

    return {
      current,
      algoliaResponse,
      facetFilters,
      numericFilters,
      page,
      query,
      isFacetActive,
      numberOfPages,
    };
  },
  watch: {
    current() {
      addFacet({ name: 'categories', option: this.current?.name });
      searchEquipment();
    },
    facetFilters: {
      deep: true,
      handler() {
        filterEquipment();
      },
    },
    numericFilters: {
      deep: true,
      handler() {
        filterEquipment();
      },
    },
  },
  methods: {
    facetBySubcategory(facet: any) {
      toggleFacet(facet);
      searchEquipment();
    },
  },
});
