
import CategoryList from '../components/Category/List.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CategoriesView',
  components: {
    CategoryList,
  },
  setup() {
  },
});
