<template>
  <tw-container is="section" class="py-5">
    <div v-if="current" class="container mx-auto">
      <h1 class="text-5xl font-bold font-secondary text-secondary uppercase">
        {{ current.name }}
      </h1>
      <div class="facets">
        <div class="flex flex-wrap -mx-2">
          <div
            v-for="subcategory in current.sub_categories"
            :key="subcategory.slug"
            class="p-2"
          >
            <button
              class="text-lg uppercase rounded px-3 py-2"
              :class="
                isFacetActive({
                  name: 'subcategories',
                  option: subcategory.name,
                })
                  ? 'text-white bg-orange-500'
                  : 'text-gray-700 bg-gray-300'
              "
              @click="
                facetBySubcategory({
                  name: 'subcategories',
                  option: subcategory.name,
                })
              "
            >
              {{ subcategory.name }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="algoliaResponse" class="grid grid-cols-a:a:1 gap-4 mt-5">
        <EquipmentFilters class="col-span-1 hidden md:block" />
        <div class="w-auto px-px bg-gray-300"></div>
        <div class="col-span-3 md:col-span-1">
          <div class="flex flex-wrap -mx-2 mt-12 items-center">
            <div
              v-for="equipment in algoliaResponse.hits"
              :key="equipment.slug"
              class="w-full sm:w-1/2 lg:w-1/3 p-2"
            >
              <EquipmentCard :equipment="equipment" class="mx-auto" />
            </div>
            <div
              v-show="algoliaResponse.nbHits === 0"
              class="w-full text-2xl my-6 p-4"
            >
              <span class="text-lg mb-8 block">
                No hay resultados con los parámetros de búsqueda.
              </span>
              <h2 :to="{ name: 'categories' }">¿Necesitas ayuda?</h2>
              <router-link
                class="text-primary text-lg"
                :to="{ name: 'categories' }"
              >
                Visita la sección de catálogo
              </router-link>
            </div>
            <SearchPagination v-show="numberOfPages() > 1" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="animate-pulse">
      <h1
        class="text-5xl inline-block rounded bg-gray-300 text-transparent px-12"
      >
        Catálogo
      </h1>
      <div class="facets">
        <div class="flex flex-wrap">
          <button
            v-for="tooltip in 6"
            :key="`tooltip-${tooltip}`"
            class="text-lg px-6 rounded-full border-2 font-medium my-2 mx-3 text-transparent bg-gray-300"
          >
            category
          </button>
        </div>
      </div>
      <div class="flex flex-wrap -mx-4 mt-12 justify-between">
        <div
          v-for="card in 20"
          :key="`card-${card}`"
          class="w-64 h-64 mx-4 my-4 bg-gray-300 rounded text-transparent"
        >
          card
        </div>
      </div>
    </div>
  </tw-container>
</template>

<script lang="ts">
import EquipmentCard from '../components/Equipment/Card.vue';
import EquipmentFilters from '../components/Equipment/Filters.vue';
import { getCategory, useCategory } from '../store/categories';
import {
  addFacet,
  toggleFacet,
  searchEquipment,
  filterEquipment,
  resetSearch,
  numberOfPages,
  useFacetFilters,
  useNumericFilters,
  usePage,
  useQuery,
  isFacetActive,
  useAlgoliaResponse,
} from '../store/search';
import { defineComponent } from 'vue';
import SearchPagination from '../components/SearchPagination.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'CategoryView',
  components: {
    EquipmentCard,
    SearchPagination,
    EquipmentFilters,
  },
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  setup() {
    const current = useCategory();
    const algoliaResponse = useAlgoliaResponse();
    const facetFilters = useFacetFilters();
    const numericFilters = useNumericFilters();
    const page = usePage();
    const query = useQuery();
    const route = useRoute();

    getCategory(route.params.category as string);
    resetSearch();

    return {
      current,
      algoliaResponse,
      facetFilters,
      numericFilters,
      page,
      query,
      isFacetActive,
      numberOfPages,
    };
  },
  watch: {
    current() {
      addFacet({ name: 'categories', option: this.current?.name });
      searchEquipment();
    },
    facetFilters: {
      deep: true,
      handler() {
        filterEquipment();
      },
    },
    numericFilters: {
      deep: true,
      handler() {
        filterEquipment();
      },
    },
  },
  methods: {
    facetBySubcategory(facet: any) {
      toggleFacet(facet);
      searchEquipment();
    },
  },
});
</script>
