<template>
  <tw-container is="section" class="py-5 space-y-5">
    <h1 class="text-4xl font-bold text-secondary font-secondary">
      Selecciona la categoría del equipo a rentar:
    </h1>
    <div>
      La manera más conveniente y sencilla de localizar el equipo de
      construcción adecuado para su operación.
    </div>
    <CategoryList />
  </tw-container>
</template>

<script lang="ts">
import CategoryList from '../components/Category/List.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CategoriesView',
  components: {
    CategoryList,
  },
  setup() {
  },
});
</script>
